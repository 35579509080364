<template>
  <div class="title" :class="[transparent ? 'transparent' : '', vertical ? 'vertical' : '']">{{title}}</div>
</template>

<script>
export default {
    name:'ImageTitle', 
    props : {
        title:String,
        vertical:Boolean,
        transparent:Boolean
    }
}
</script>

<style lang="scss" scoped>
    .title {
        font-family: 'Helvetica Neue LT Std';
        font-weight: 900;
        color: #B6B6B6;
        font-size: 1rem;
        letter-spacing: 0;
        line-height: 1;
        padding:20px 32px;
        font-size: 1.5rem;
        &:not(.transparent) {
            background-color: rgba(#070707, 0.5);
        }        
    }    
    .vertical {
        padding:20px;
        writing-mode: vertical-lr;
        position: absolute;
        right: 0;
        bottom: 0;
        height: 100%;
        box-sizing: border-box;
        transform: scale(-1);
    }   
</style>