<template>
  <div class='template'>    
      <article class='text-content'>
          <div class='text-content-limit-width'>
            <transition name="slide" appear>
                <div>
                    <div class='copy-padding'>
                        <div>
                            <ImageTitle transparent :title="page.title"/>              
                            <Editor @click="activateEditor" plugins="lists" api-key="2uyqbbnykquw7zpsd3ht43lzwe4s4dyu3l8x6p7vgvx8v4ug" :init="{menubar:false}" toolbar='[ undo redo | bold italic underline | fontsize forecolor | bullist numlist link ]' v-model="copy" class="copy" v-if="copy || $store.state.devMode" inline :disabled="!editorFocused" @change="updateContent"/>
                            <AttachmentUI :page="page" :gallery="gallery" @showGallery="galleryVisible = true" @showVideo="showVideo"/>         
                        </div>
                    </div>
                    <div class="fixed-image">
                        <img :src="page?.background" alt="">    
                    </div>                    
                </div>
            </transition>            
          </div>
          <div class="linear-holder">
            <LinearButton :to="page?.previous" previous/>
            <LinearButton :to="page?.next"/>
          </div>
      </article>      
      <transition name="fade">
        <Gallery :images="gallery" @close="galleryVisible = false" v-show="galleryVisible"/>
      </transition>
      <transition name="fade">
        <Gallery videoMode :images="[]" :videoId="videoId" @close="videoVisible = false" v-if="videoVisible" :show="videoVisible"/>
      </transition>
  </div>  
</template>

<script>
import Page from '@/mixins/Page';
import AttachmentUI from '@/components/AttachmentUI';
import LinearButton from '@/components/LinearButton';
import ImageTitle from '@/components/ImageTitle';
import Gallery from '@/components/Gallery';
export default {
    name:'Image Spotlight',
    mixins: [Page],
    props: {
        page:Object
    },
    components:{
        AttachmentUI,
        Gallery,
        LinearButton,
        ImageTitle
    },
    data() {
        return {
            galleryVisible:false,
            videoVisible:false,
            imagesFromGallery:0,
            copy:this.page.copy               
        }
    },
    computed: {       
        gallery() {
            let images = [];        
            for (let index = this.imagesFromGallery; index < this.page.gallery?.length; index++) {
                const element = this.page?.gallery[index];
                images.push({
                    file:element.file,
                    title:element.title
                });
            }
            return images;
        }
    }
}
</script>

<style lang="scss" scoped>
    @media (max-width:699px) {  
        .linear-holder {
            position: relative;
            height:90px;
        }
    }
    .template {
        display:grid;
        place-items: center;
        position: relative;              
        background:url('~@/assets/images/bg_texture.jpg');  
        background-size: cover;
    }    
    .text-content {
        -webkit-print-color-adjust: exact;   /* Chrome, Safari, Edge */
        color-adjust: exact;     
        box-sizing: border-box;
        color: #F5F5F5;
        position: relative;                        
        @media (min-width:700px) {                        
            .text-content-limit-width {
                max-width:1000px;
                margin:0 auto;                
            }
            .text-content-limit-width > div:not(.title) {
                position: relative;
                > div {
                    position: relative;
                }                
            }
            .text-content-limit-width > div {
                display:grid;
                grid-template-columns: 1fr max-content;    
                place-items: center;
                gap:30px;
                padding:0;
                &:before {
                    content:"";
                    position: absolute;
                    top:0;
                    left:0;
                    bottom:-90px;
                    width:100%;                    
                    border:1px solid #0066CC;
                    border-top:none;
                    margin-top:120px;
                    box-sizing: border-box;
                }
                > div:not(.copy) {                    
                    max-width: 550px;                    
                    margin-left:auto;
                    width:100%;
                }
            
            }
        }
        @media print {
            > div {
                grid-template-columns: max-content 1fr;
                .copy {
                    padding-right:0;
                }
                div:not(.copy) {
                    margin-left:0;
                }
            }
        }                    
    }           
    .fixed-image {
        display: block;                
        img {
            width:100%;
            @media (min-width:700px) {
                width:calc(100% + 50px);
                max-width: calc(58vw - 50px);        
            }
        }
        @media (max-width:699px) {
            &:before {
                background-image:url('~@/assets/images/texture_black-asphalt.jpg');
                content:"";
                height:15px;
                display:block;
                background-size: cover;

            }
        }
    }
    .text-content .title {        
        text-transform: uppercase;
        padding:0;
        padding-bottom: 45px;
    }  
    .copy-padding {
        padding:50px;
        box-sizing: border-box;
        position: relative;
        @media (min-width:700px) {
            background-color: rgba(18,18,18,1);   
            &:before {
                content:"";
                height:100%;
                width:200%;
                transform: translateX(-100%);
                background-color: rgba(18,18,18,1);   
                position: absolute;
                left:0;
                top:0;
            }
            > div {
                position: relative;
            }
        }        
    }
</style>